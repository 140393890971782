.root {
  display: flex;
  align-items: center;
  width: 970px;
  height: 380px;
  white-space: pre;
  flex-direction: column;
}

.title {
  font-weight: bold;
  font-size: 48px;
  position: absolute;
  transition: top 0.8s;
  left: 472px;
}

.body {
  width: 100%;
  height: 100%;
}
