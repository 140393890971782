.game_manager-qr {
  background-image: url('../assets/doodleDash/phoneSide.webp');
  position: absolute;
  top: 5%;
  right: 0;
  width: 342px;
  height: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: game_manager-qr_in 1s;
}

.game_manager-qr_body {
  margin-left: 80px;
  color: white;
  margin-bottom: 10px;
  text-align: end;
}

@keyframes game_manager-qr_in {
  0% {
    transform: translateX(100%);
  }
  100% {
  }
}
