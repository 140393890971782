.playing-mask {
  mask-image: url('../../assets/mask.webp');
  width: 847px;
  height: 549px;
  mask-repeat: no-repeat;
  position: absolute;
  mask-position: center;
  mask-size: 849px;
  margin-right: 23px;
}

.playing-options_container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-row-gap: 50px;
  position: absolute;
  bottom: 50px;
  grid-column-gap: 55px;
}

.playing-timer {
  transform: scale(0);
  border: 4px solid #4bc4e7;
  border-radius: 50%;
  position: absolute;
  right: 150px;
  box-shadow: rgba(75, 196, 231, 0.5) 0px 0 15px 11px;
}

.playing-timer-on {
  animation: playing-timer-enter 1s forwards;
}

@keyframes playing-timer-enter {
  0% {
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.reveal_sentence-question_container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/questionContainer.webp');
  height: 229px;
  width: 1062px;
  text-align: center;
  transition: margin 0.7s;
  z-index: 1;
  position: relative;
}
.reveal_sentence-question_title {
  position: absolute;
  top: 11px;
  left: 65px;
  color: #62fff6;
}

.reveal_sentence-question_body {
  margin-top: 3%;
  width: 90%;
  text-shadow: #62fff6 1px 0 5px;
}

.countdown {
  background-image: url('../../assets/countdown.webp');
  width: 190px;
  height: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #8affff;
  font-size: 80px;
  font-family: conthrax;
  margin-top: -80px;
  animation: countdownAppear 0.5s;
}

@keyframes countdownAppear {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.countdownTitle {
  font-size: 150px;
  text-align: center;
  width: 800px;
}

.countdownSubTitle {
  font-size: 60px;
}

.scoreRow {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 310px;
}
.footerTitle {
  position: absolute;
  font-size: 50px;
  background-image: url('../../assets/scoreboardContainer.webp');
  width: 568px;
  height: 98px;
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  align-items: flex-start;
}

.powerUpText {
  animation: powerUpTextShow 3s;
  position: absolute;
  font-size: 80px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  padding: 40px;
  transform: scale(0);
}

.reminderText {
  text-align: center;
  animation: powerUpTextShow 5s;
  position: absolute;
  font-size: 50px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 3px;
  padding: 40px;
  transform: scale(0);
  white-space: pre-line;
}

@keyframes powerUpTextShow {
  30% {
    transform: scale(1.4);
  }
  40% {
    transform: scale(1.3);
  }
  50% {
    transform: scale(1.5);
    opacity: 1;
  }
  60% {
    transform: scale(1.4);
    opacity: 1;
  }
  100% {
    transform: scale(1.7);
    opacity: 0;
  }
}
