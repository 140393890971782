.background_root {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%;
  display: flex;
  transition: transform ease-out;
  background-image: url('../../assets/backgroundSm.webp');
}

.background_child-container {
  flex-direction: column;
  position: absolute;
  top: 0;
  width: 1920px;
  height: 86%;
  display: flex;
}
