.yard-and-display_root {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.yard-and-display_title-img {
  background-image: url('./assets/doodleDash/scanTitle.webp');
  width: 1078px;
  height: 230px;
  position: absolute;
  top: 10%;
  background-repeat: no-repeat;
  background-size: contain;
  animation: yard-and-display_title-img-enter 1s 1s forwards;
  transform: translateY(-100vh);
}

@keyframes yard-and-display_title-img-enter {
  50% {
    transform: translateY(10vh) rotate(5deg);
  }
  100% {
    transform: translateY(0);
  }
}

.yard-and-display_mascot {
  background-image: url('./assets/doodleDash/redMascotQR.webp');
  width: 406px;
  height: 473px;
  position: absolute;
  bottom: -1%;
  left: 19%;
  background-repeat: no-repeat;
  background-size: contain;
  animation: yard-and-display_mascot-enter 1s 1.5s forwards;
  transform: translateX(-100vw);
  opacity: 0;
}

@keyframes yard-and-display_mascot-enter {
  0% {
    opacity: 1;
  }
  50% {
    transform: translateX(10vw) rotate(-5deg);
    opacity: 1;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.yard-and-display_title {
  font-size: 164px;
  align-self: center;
  letter-spacing: 10px;
}

.yard-and-display_qr-child-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 375px;
  height: 377px;
}

.yard-and-display_qr-child-container-A {
  position: relative;
}

.yard-and-display_qr-child-container-B {
  position: absolute;
  right: 120px;
}

.yard-and-display_qr {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 92%;
  height: 92%;
  border-radius: 4%;
  border: 10px solid #26beff;
  box-shadow: rgba(38, 190, 255, 0.35) 0 0 15px 10px;
}

.yard-and-display_code {
  color: white;
  position: absolute;
  top: 370px;
  font-size: 40px;
  text-align: center;
  line-height: 1;
  margin-top: 55px;
}

.yard-and-display_buttons-container-A {
  display: flex;
  position: absolute;
  bottom: 50px;
  right: -200px;
}

.yard-and-display_buttons-container-B {
  display: flex;
  position: absolute;
  bottom: 50px;
  right: -1000px;
}

.yard-and-display_button-with-title-container {
  text-align: center;
  font-size: 50px;
}

.yard-and-display_button {
  height: 110px;
  width: 400px;
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('./assets/doodleDash/btn.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.yard-and-display_selected-button {
  background-image: url('./assets/doodleDash/btnSuccess.webp');
}

.yard-and-display_mascot-b {
  background-image: url('./assets/doodleDash/bTestMascot.webp');
  width: 1513px;
  height: 1124px;
  position: absolute;
  top: 15%;
  left: 0;
  background-repeat: no-repeat;
  background-size: contain;
  animation: yard-and-display_mascot-enter 1s 1.5s forwards;
  transform: translateX(-1513px);
  opacity: 0;
}

.yard-and-display_title-img-b {
  background-image: url('./assets/doodleDash/bTestTitle.webp');
  width: 593px;
  height: 394px;
  position: absolute;
  top: 1.5%;
  right: 0.7%;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: contain;
  animation: yard-and-display_title-img-enter 1s 1s forwards;
  transform: translateY(-100vh);
}

.selfie {
  mask-image: url('./assets/playerFrameMask.webp');
  mask-repeat: no-repeat;
  mask-position: center;
  width: 130px;
  height: 130px;
  background-position: center;
  background-size: contain;
  border-radius: 50%;
  background-repeat: no-repeat;
}

.selfie_name {
  text-align: center;
  white-space: pre;
  line-height: 1;
  font-size: 36px;
  position: absolute;
  bottom: -35px;
}

.selfie_name-optional {
  opacity: 0.5;
}
.selfie_name-dummy {
  margin-top: -60px;
}
.selfie_name-dummy-spacing {
  letter-spacing: 0;
}

@keyframes FaceFloat {
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(5px);
  }
  75% {
    transform: translateY(-2.5px);
  }
}

.pick_team-player_frame {
  background-image: url('./assets/playerFrame.webp');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  width: 130px;
  height: 130px;
}

.scanToPlay {
  position: absolute;
  top: 60px;
  font-size: 64px;
  animation: scanToPlay 6s infinite;
}

.yourPhoneController {
  position: absolute;
  top: 160px;
  font-size: 42px;
}

@keyframes scanToPlay {
  0% {
    transform: rotate(0) scale(1);
  }
  10% {
    transform: rotate(7deg) scale(1.2);
  }
  20% {
    transform: rotate(0) scale(1);
  }
}
