.qr-sign_root {
  position: absolute;
  left: 17%;
  top: 5%;
  z-index: 1;
}

.qr-sign_body {
  margin-right: 5%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 70%;
  height: 70%;
}

.qr-sign_qr {
  mix-blend-mode: multiply;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-size: contain;
}

.qr-sign_text {
  line-height: 1;
  margin-top: -1%;
  font-size: 100%;
}
