.use-phone-overlay_root {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  opacity: 0;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.use-phone-overlay_root-animated {
  animation: use-phone-overlay_blink 3s cubic-bezier(0.69, 0.35, 0.94, 0.21);
}

@keyframes use-phone-overlay_blink {
  25% {
    opacity: 1;
  }
}
