.scoring_transition-root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.scoring_transition-title {
  font-size: 40px;
  margin-bottom: 30px;
}

.scoring_transition-icon_container {
  width: 150px;
  height: 150px;
  margin-left: -100px;
  margin-right: 40px;
  position: absolute;
  left: 40px;
}

.points {
  font-size: 100px;
  animation: score 1s 1s forwards;
  transform: scale(0);
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  z-index: 100;
  width: 300px;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
}

@keyframes score {
  0% {
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
