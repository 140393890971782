@font-face {
  font-family: 'conthrax';
  src: url('assets/conthrax-sb.otf') format('opentype');
}

@font-face {
  font-family: 'bahnschrift';
  src: url('assets/BAHNSCHRIFT.TTF') format('truetype');
}

.root-gptrivia-display {
  color: white;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.root-gptrivia-display {
  color: white;
  margin: 0;
  font-family: 'bahnschrift', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 36px;
  -webkit-tap-highlight-color: transparent;
}

.root-gptrivia-display * {
  font-family: 'bahnschrift', sans-serif;
}
