.mascot-root_animation {
  animation: mascot-float 3s infinite;
  transition: top 0.5s, left 0.5s, right 0.5s, bottom 0.5s;
  position: absolute;
  width: 325px;
  height: 400px;
}

.mascot-root {
  background-image: url('./assets/mascot.webp');
  width: 100%;
  height: 100%;
  transition: transform 0.5s;
  background-size: contain;
  background-repeat: no-repeat;
}

@keyframes mascot-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(20px);
  }
  100% {
    transform: translateY(0);
  }
}
