.initial-loading_root {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.initial-loading_title {
  width: 1397px;
  height: 734px;
  background-size: cover;
  animation: 1s initial-loading_title-enter;
}

@keyframes initial-loading_title-enter {
  0% {
    transform: translateY(-100vh);
  }
  50% {
    rotate: 5deg;
    transform: translateY(10vh);
  }
}

.initial-loading_btn {
  font-size: 60px;
  margin-top: 100px;
  width: 424px;
  height: 128px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
