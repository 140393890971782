.scoring_root {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.scoring_row {
  background-image: url('../assets/scoreRow.webp');
  width: 687px;
  height: 50px;
  display: flex;
  margin-bottom: 44px;
  background-size: contain;
  background-repeat: no-repeat;
}

.scoring_row-body {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.scoring_row-icon {
  margin-left: -15px;
  margin-right: 20px;
  width: 90px;
  height: 90px;
}

.announcement {
  position: absolute;
  top: 100px;
  background-image: url('../assets/announcement.webp');
  width: 1225px;
  height: 443px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 100px;
}

.highlight {
  background-image: url('../assets/scoreRowSelected.webp');
}

.large {
  transform: scale(1.3);
  margin-bottom: 70px;
}

.crown {
  background-image: url('../assets/crownTeamScore.webp');
  background-repeat: no-repeat;
  background-size: contain;
  width: 85px;
  height: 85px;
  position: absolute;
  left: -93px;
  top: -59px;
  z-index: 1;
}

.body {
  margin-top: 250px;
  height: 500px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 800px;
  justify-content: space-evenly;
  color: #6efffd;
}

.playerContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 40px;
  width: 201px;
  height: 230px;
  overflow: hidden;
}

.playerName {
  margin-top: -20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  text-align: center;
}
