.winner_transition-root {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.date {
  position: absolute;
  top: 120px;
  right: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.topic {
  text-align: right;
  position: absolute;
  top: 120px;
  left: 300px;
  width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titleContainer {
  position: absolute;
  top: 85px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerContainer {
  position: absolute;
  bottom: 85px;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: footerContainerAnimation 8s infinite;
}

@keyframes footerContainerAnimation {
  0% {
    transform: scale(1);
  }
  12% {
    transform: scale(1.4) rotate(4deg);
  }
  24% {
    transform: scale(1);
  }
}

.title {
  font-size: 60px;
  background-image: url('../../assets/scoreboardContainer.webp');
  width: 568px;
  height: 98px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rows {
  position: absolute;
  top: 300px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  left: 100px;
}
